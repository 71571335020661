import classNames from 'classnames';
import {useEffect, useState} from 'react';
import {useForm, ValidateResult} from 'react-hook-form';

import {Button, BUTTON_STYLES} from '@/components/base/button/Button';
import {Input} from '@/components/base/input/Input';
import {INPUT_STYLES} from '@/components/base/input/types';
import {RequiredInput} from '@/components/base/required-input/RequiredInput';
import {TEXTAREA_STYLES} from '@/components/base/textarea/Textarea';
import {Additions} from '@/components/common/forms/additions/Additions';
import {AgreementBlock} from '@/components/common/forms/agreement-block/AgreementBlock';
import {getQuestions, getRecaptchaDisclaimer} from '@/components/common/forms/request-form/helpers';
import styles from '@/components/common/forms/request-form/RequestForm.module.css';
import {PHONE_MASK} from '@/constants';
import {withRecaptcha} from '@/hocs/with-recaptcha';
import {MeStore} from '@/mobx/business-models/me/MeModel';
import {RequestPostData} from '@/services/api/interfaces/request';
import {setGAClientId} from '@/utilites/analytics';
import {getFormError} from '@/utilites/get-form-error/get-form-error';
import {validatePhone} from '@/utilites/validate-phone/validate-phone';

interface RequestFormProps {
	onSubmit?: (data: RequestPostData) => void;
	onClick?: () => void;
	onClose?: () => void;
	autoFocusFirstInput?: boolean;
	buttonText?: string;
	buttonClassName?: string;
	inputStyle?: INPUT_STYLES;
	textareaStyle?: TEXTAREA_STYLES;
	buttonStyle?: BUTTON_STYLES;
	controlClassName?: string;
	contactsClassName?: string;
	buttonWrapperClassName?: string;
	className?: string;
	commentText?: string;
	disclaimer?: JSX.Element | string;
	feedbackQuestions?: JSX.Element | string;
}

const phoneId = 'phone';
const emailId = 'email';
const nameId = 'name';
const companyId = 'company';

// eslint-disable-next-line max-lines-per-function
function PureRequestForm({
	onSubmit,
	onClick,
	autoFocusFirstInput,
	buttonText,
	buttonClassName,
	buttonStyle,
	inputStyle,
	controlClassName,
	contactsClassName,
	buttonWrapperClassName,
	className,
	disclaimer = getRecaptchaDisclaimer(),
	feedbackQuestions = getQuestions(),
}: RequestFormProps) {
	const {
		register,
		handleSubmit,
		reset,
		formState: {errors, isSubmitSuccessful},
	} = useForm();
	const [isAgreement, setIsAgreement] = useState(false);

	const {phone, email} = MeStore;
	useEffect(() => {
		reset({
			[phoneId]: phone,
			[emailId]: email,
			[nameId]: '',
			[companyId]: '',
		});
	}, [email, isSubmitSuccessful, phone, reset]);

	useEffect(() => {
		setGAClientId();
	}, []);

	return (
		<form onSubmit={handleSubmit(onSubmit)} className={className}>
			<div className={classNames(styles.contacts, contactsClassName)}>
				<RequiredInput
					id={phoneId}
					autoFocus={autoFocusFirstInput}
					autocomplete="tel"
					type="tel"
					mask={PHONE_MASK}
					placeholder="Телефон"
					controlClassName={classNames(styles.control, [styles.contactsControl, controlClassName])}
					inputStyle={inputStyle}
					registerProps={register(phoneId, {
						required: true,
						validate: (value): ValidateResult =>
							validatePhone(value) ? true : 'Некорректный номер телефона',
					})}
					error={getFormError(errors[phoneId])}
				/>
				<Input
					id={emailId}
					autocomplete="email"
					placeholder="Электронная почта"
					className={classNames(styles.control, [styles.contactsControl, controlClassName])}
					inputStyle={inputStyle}
					{...register(emailId, {
						required: false,
					})}
				/>
			</div>
			<RequiredInput
				id={nameId}
				autocomplete="name"
				placeholder="Ваше имя"
				registerProps={register(nameId, {required: true})}
				controlClassName={classNames(styles.control, controlClassName)}
				inputStyle={inputStyle}
				error={getFormError(errors[nameId])}
			/>
			<Input
				id={companyId}
				autocomplete="organization"
				placeholder="Компания"
				{...register(companyId, {required: false})}
				className={classNames(styles.control, controlClassName)}
				inputStyle={inputStyle}
			/>
			<AgreementBlock value={isAgreement} onChange={() => setIsAgreement((v) => !v)} />
			{(feedbackQuestions || disclaimer) && (
				<Additions feedbackQuestions={feedbackQuestions} disclaimer={disclaimer} />
			)}
			<div className={classNames(styles.buttonWrapper, buttonWrapperClassName)}>
				<Button
					disabled={!isAgreement}
					type="submit"
					className={classNames(styles.button, buttonClassName)}
					style={buttonStyle}
					onClick={onClick}
				>
					{buttonText ?? 'Задать вопрос'}
				</Button>
			</div>
		</form>
	);
}

export const RequestForm = withRecaptcha(PureRequestForm);
