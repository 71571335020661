import {StaticImageData} from 'next/image';
import {VFC} from 'react';

import CalendarIcon from '@/components/common/advanced-header/icons/calendar.svg';
import DiskIcon from '@/components/common/advanced-header/icons/disk.svg';
import DocumentsIcon from '@/components/common/advanced-header/icons/documents.svg';
import MailIcon from '@/components/common/advanced-header/icons/mail.svg';
import MessengerIcon from '@/components/common/advanced-header/icons/messenger.svg';
import TasksIcon from '@/components/common/advanced-header/icons/tasks.svg';
import VideoConferenceIcon from '@/components/common/advanced-header/icons/videoconference.svg';

import CalendarDark from './assets/calendarDark.webp';
import DiskDark from './assets/diskDark.webp';
import DocumentsDark from './assets/documentsDark.webp';
import MailDark from './assets/mailDark.webp';
import MessengerDark from './assets/messengerDark.webp';
import TasksDark from './assets/tasksDark.webp';
import VideoDark from './assets/videoDark.webp';

export type ItemProps = {
	id: number;
	title: string;
	text: string;
	img: VFC<React.SVGProps<SVGSVGElement>>;
	imgDark: StaticImageData;
};

export const data: ItemProps[] = [
	{
		id: 0,
		title: 'Почта',
		text: 'Подключайте свой домен, любое количество пользователей. Объём почтового ящика не\u00A0ограничен',
		img: MailIcon,
		imgDark: MailDark,
	},
	{
		id: 1,
		title: 'Календарь',
		text: `Планируйте совместные встречи, прикрепляйте файлы в\u00A0события, делитесь доступом к\u00A0календарю с\u00A0коллегами`,
		img: CalendarIcon,
		imgDark: CalendarDark,
	},
	{
		id: 2,
		title: 'Мессенджер',
		text: `Общайтесь в\u00A0чатах и\u00A0каналах, переписывайтесь в\u00A0обсуждениях, создавайте папки чатов`,
		img: MessengerIcon,
		imgDark: MessengerDark,
	},
	{
		id: 3,
		title: 'Видеоконференции',
		text: `Звоните коллегам без\u00A0ограничений по\u00A0времени, проводите вебинары, приглашайте в\u00A0звонок гостей по\u00A0ссылке`,
		img: VideoConferenceIcon,
		imgDark: VideoDark,
	},
	{
		id: 4,
		title: 'Задачи',
		text: `Используйте менеджер задач, ставьте таски себе и\u00A0коллегам, назначайте дедлайны и\u00A0приоритеты, отслеживайте статусы выполнения задач`,
		img: TasksIcon,
		imgDark: TasksDark,
	},
	{
		id: 5,
		title: 'Диск',
		text: `Выбирайте любое количество пользователей и\u00A0объём, создавайте общие папки, управляйте правами доступа к\u00A0файлам`,
		img: DiskIcon,
		imgDark: DiskDark,
	},
	{
		id: 6,
		title: 'Документы',
		text: `Редактируйте документы, таблицы и\u00A0презентации онлайн вместе с\u00A0коллегами, отслеживайте изменения в\u00A0файлах, предоставляйте доступ по\u00A0ссылке`,
		img: DocumentsIcon,
		imgDark: DocumentsDark,
	},
];
